.slide-next-bottom {
  position: fixed;
  z-index: 99;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  border: 0;
  padding: 0;
  color: #fff;
  font-size: 26px;
  outline: none !important;
  animation: slide-next-bottom 3s infinite;
  @keyframes slide-next-bottom {
    50% {
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
.app-slider {
  width: 80%;
  margin: auto;

  .slick-dots {
    li {
      margin: 0 2px;
      button {
        &::before {
          color: #fff;
        }
      }
    }
  }
  .slick-arrow {
    color: #fff;
    height: auto;
    z-index: 22;
    &::before {
      display: none;
    }
    &.slick-prev {
      left: 8px;
    }
    &.slick-next {
      right: 8px;
      svg {
        transform: scaleX(-1);
      }
    }
  }
}
.page-slider {
  border: 0;
  height: 100vh;
  & > .slick-list {
    height: 100vh !important;
  }
  .slick-slide {
    height: 100vh;
    .slick-slide {
      display: flex;
      align-items: center;
    }
    .contnet {
      > * {
        transition: 1s;
      }
    }
    &:not(.slick-current) {
      .contnet {
        > div {
          transform: translateY(50%);
          opacity: 0;
        }
      }
    }
  }
  & > .slick-dots {
    transform: rotate(90deg) translate(8%, 10%);
    right: 0;
    bottom: initial;
    transform-origin: 93% 128%;
    width: 97vh;
    top: 79%;
    li {
      margin: 0 2px;
      button {
        &::before {
          color: #fff;
          content: "";
          width: 5px;
          height: 15px;
          border-radius: 5px;
          background-color: #fff;
        }
      }
      &.slick-active {
        button {
          &::before {
            height: 30px;
          }
        }
      }
    }
  }
  .slide-item-container {
    padding: 12px;
    height: calc(100vh - 4px);
  }
  .slide-item {
    position: relative;
    height: 100%;
    overflow: hidden;
    .slide-item {
      height: auto;
    }
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-size: cover;
      z-index: 1;
      animation: sliide-bg 8s infinite;
      background-position: center;
      @keyframes sliide-bg {
        50% {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
    .contnet {
      position: relative;
      height: 100%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      .content-banner {
        color: #fff;
        width: 80%;
        @media (max-width: $tablet) {
          text-align: center;
        }
        @media (max-width: 374px) {
          transform: scale(0.8);
        }
        h1 {
          @media (max-width: $desktop) {
            font-size: 32px;
          }
          @media (max-width: $tablet) {
            font-size: 22px;
          }
          // @media (max-width: $phone) {
          //   font-size: 18px;
          // }
        }
        p {
          @media (max-width: $tablet) {
            font-size: 14px;
          }
          // @media (max-width: $phone) {
          //   font-size: 12px;
          // }
        }
        .btn {
          @media (max-width: $tablet) {
            font-size: 12px;
          }
        }
        img {
          // @media (max-width: $tablet) {
          //   max-width: 270px;
          //   display: block;
          //   margin: 20px auto 0;
          // }
        }
      }
      .content-msg {
        width: 80%;
        max-width: 900px;
        &.c-view {
          border-radius: 6px;
          background-color: rgba($primary, 0.42);
        }
        h2 {
          @media (max-width: $tablet) {
            font-size: 28px;
          }
          @media (max-width: $phone) {
            font-size: 22px;
          }
        }
        h3 {
          @media (max-width: $tablet) {
            font-size: 24px;
          }
          @media (max-width: $phone) {
            font-size: 20px;
          }
        }
        p {
          @media (max-width: $tablet) {
            font-size: 14px;
          }
        }
      }
      .content-card {
        width: 80%;
        text-align: center;
        padding: 20px;
        background-color: rgba(#000, 0.65);
        color: #fff;
      }
      .content-intro {
        width: 80%;
        max-width: 1000px;
        @media (max-width: $phone) {
          width: 100%;
          transform: scale(0.8);
        }
        .intro-item {
          height: 100%;
          padding: 20px;
          text-align: center;
          background-color: rgba(#000, 0.65);
          color: #fff;
          border-radius: 6px;
          &.i-1 {
            background-color: rgba(#ff6663, 0.9);
          }
          &.i-2 {
            background-color: rgba(#79addc, 0.9);
          }
          &.i-3 {
            background-color: rgba(#ffcc16, 0.9);
          }
          &.i-4 {
            background-color: rgba(#47e5bc, 0.9);
          }
          @media (max-width: $desktop) {
            padding: 16px;
          }
          @media (max-width: $phone) {
            padding: 12px;
          }

          img {
            display: inline;
            height: 50px;
            margin-bottom: 12px;
            @media (max-width: $desktop) {
              height: 35px;
              margin-bottom: 8px;
            }
            @media (max-width: $phone) {
              height: 30px;
              margin-bottom: 6px;
            }
          }
          h2 {
            font-size: 28px;
            @media (max-width: $desktop) {
              font-size: 22px;
            }
            @media (max-width: $tablet) {
              font-size: 18px;
            }
            @media (max-width: $phone) {
              font-size: 16px;
            }
          }
          p {
            margin-bottom: 0;
            @media (max-width: $desktop) {
              font-size: 14px;
            }
            @media (max-width: $phone) {
              font-size: 10px;
            }
          }
        }
      }
      .content-what-we-do {
        width: 80%;
        @media (max-width: 374px) {
          transform: scale(0.8);
        }
        .m-title {
          text-align: center;
          margin-bottom: 42px;
          @media (max-width: $tablet) {
            margin-bottom: 24px;
            font-size: 22px;
          }
        }
        .item {
          height: 100%;
          padding: 20px;
          @media (max-width: $desktop) {
            padding: 16px;
          }

          img {
            display: inline;
            height: 80px;
            margin-bottom: 12px;
            @media (max-width: $desktop) {
              height: 50px;
            }
            @media (max-width: $tablet) {
              height: 40px;
              margin-bottom: 8px;
            }
          }
          text-align: center;
          background-color: rgba(#000, 0.65);
          color: #fff;
          h2 {
            font-size: 18px;
            @media (max-width: $desktop) {
              font-size: 16px;
            }
            @media (max-width: $phone) {
              font-size: 14px;
            }
          }
          p {
            @media (max-width: $desktop) {
              font-size: 14px;
            }
            @media (max-width: $phone) {
              font-size: 12px;
              margin-bottom: 0;
            }
          }
        }
      }

      .content-testimonial {
        width: 80%;
        max-width: 900px;
        margin: auto;
        @media (max-width: 374px) {
          transform: scale(0.8);
        }
        @media (max-width: $phone) {
          transform: scale(0.8);
        }
        .m-title {
          text-align: center;
          margin-bottom: 42px;
          @media (max-width: $tablet) {
            margin-bottom: 24px;
            font-size: 22px;
          }
        }
        .item {
          height: 100%;
          padding: 20px;
          text-align: center;
          background-color: rgba(#000, 0.65);
          color: #fff;
          @media (max-width: $desktop) {
            padding: 16px;
          }

          .msg {
            font-size: 15px;
            @media (max-width: $tablet) {
              margin-bottom: 8px;
            }
          }
          .client {
            text-align: right;
            margin-bottom: 0;
            font-size: 13px;
          }
          .project {
            text-align: right;
            margin-bottom: 0;
            font-size: 11px;
          }
        }
      }

      .content-full-stack {
        width: 80%;
        @media (max-width: $tablet) {
          max-width: 500px;
        }
        @media (max-width: 400px) {
          transform: scale(0.8);
        }
        .m-title {
          text-align: center;
          margin-bottom: 42px;
          color: #fff;
          text-shadow: 1px 2px #595959;
          @media (max-width: $tablet) {
            margin-bottom: 24px;
            font-size: 22px;
          }
        }
        .item {
          height: 100%;
          padding: 20px;
          text-align: center;
          background-color: rgba(#fff, 0.82);
          @media (max-width: $tablet) {
            padding: 16px;
          }
          @media (max-width: 400px) {
            padding: 12px;
          }
          img {
            display: inline;
            height: 40px;
            @media (max-width: $tablet) {
              height: 30px;
            }
            @media (max-width: 400px) {
              height: 26px;
            }
          }
          h2 {
            font-size: 18px;
          }
        }
      }
      .content-form {
        max-width: 800px;
        padding: 20px;
        width: 80%;

        border-radius: 6px;
        background-color: rgba(#fff, 0.82);
        @media (max-width: $tablet) {
          transform: scale(0.8);
        }
        @media (max-width: $desktop) {
          padding: 16px;
        }
        @media (max-width: $tablet) {
          width: 90%;
        }
        > div {
          padding: 12px 20px;
          @media (max-width: $desktop) {
            padding: 8px 16px;
          }
          @media (max-width: $phone) {
            padding: 8px 0px;
          }
        }
        .form-group {
          @media (max-width: $tablet) {
            margin-bottom: 8px;
          }
        }
        input.form-control {
          @media (max-width: $tablet) {
            height: 28px;
          }
        }
        textarea {
          min-height: 40px;
          @media (max-width: $tablet) {
            height: 40px;
          }
        }
        h3 {
          @media (max-width: $tablet) {
            font-size: 20px;
            margin-bottom: 8px !important;
          }
        }
        .btn {
          @media (max-width: $tablet) {
            font-size: 14px;
          }
        }
        label {
          @media (max-width: $tablet) {
            font-size: 14px;
          }
        }
      }
      .content-footer {
        width: 80%;
        max-width: 700px;
        @media (max-width: $phone) {
          width: 90%;
          transform: scale(0.8);
        }
        .item {
          padding: 20px;
          color: #fff;

          background-color: rgba(#000, 0.65);
          .msg {
            text-align: center;
            @media (max-width: $phone) {
              font-size: 14px;
            }
          }
          .list-title {
            font-size: 22px;
            margin-bottom: 8px;
            @media (max-width: $phone) {
              font-size: 18px;
            }
          }
          ul {
            padding-left: 18px;
            @media (max-width: $phone) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.client-success {
  @media (max-width: $tablet) {
    font-size: 28px;
  }
  @media (max-width: $phone) {
    font-size: 22px;
  }
}
