.home-logo {
  position: fixed;
  z-index: 99;
  left: 50%;
  top: 20px;
  height: 70px;
  transform: translateX(-50%);
  @media (max-width: $phone) {
    height: 30px;
  }
}
